import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '@hooks/context/useGlobalContext';
import { routes } from '@const/routes';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Tabs from '@components/tabs';
import PersonalSection from '@sections/consumer/personal';
import BusinessSection from '@sections/consumer/business';
import CardsSection from '@sections/consumer/cards';
import TransactionsSection from '@sections/consumer/transactions';
import KYCSection from '@sections/consumer/kyc';
import RequestsSection from '@sections/consumer/requests';
import LogsSection from '@sections/consumer/logs';
import { useParams } from 'react-router-dom';
import { useConsumers } from '@hooks/api/useConsumers';

const tabs = [
    {
        label: 'Personal Information',
        value: 'info'
    },
    {
        label: 'Business Information',
        value: 'business'
    },
    {
        label: 'Cards',
        value: 'cards'
    },
    {
        label: 'Transaction History',
        value: 'transaction'
    },
    {
        label: 'KYC',
        value: 'kyc'
    },
    {
        label: 'Requests',
        value: 'requests'
    },
    {
        label: 'Logs',
        value: 'logs'
    }
];

const ConsumerLayout = () => {
    const { handleChangeBackLabel } = useGlobalContext();
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const { id } = useParams<{ id: string }>();

    const { consumer } = useConsumers({
        isInitialConsumer: true
    });

    useEffect(() => {
        handleChangeBackLabel({ label: 'Consumers List', route: routes.consumers });
        return () => {
            handleChangeBackLabel({
                label: '',
                route: ''
            });
        };
    }, [id]);

    const renderContent = () => {
        switch (activeTab) {
            case 'info':
                return <PersonalSection consumer={consumer} />;
            case 'business':
                return <BusinessSection consumer={consumer} />;
            case 'cards':
                return <CardsSection />;
            case 'transaction':
                return <TransactionsSection />;
            case 'kyc':
                return <KYCSection />;
            case 'requests':
                return <RequestsSection />;
            case 'logs':
                return <LogsSection />;
            default:
                return null;
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Consumer Profile</Typography>
                </div>
            </div>
            <Tabs
                onChange={(val) => setActiveTab(val)}
                tabs={tabs.filter((item) => {
                    if (item.value === 'business') {
                        //@ts-ignore
                        return !!consumer?.business;
                    }
                    return true;
                })}
                activeTab={activeTab}
            />
            <div className={styles.content}>{renderContent()}</div>
        </div>
    );
};

export default ConsumerLayout;
