import React, { useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import Status from '@components/common/status';
import PriceStatus from '@components/common/price_status';
import Typography from '@components/common/typography';
import RangeDatePicker from '@components/range_date_picker';
import Button from '@components/common/button';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Accordion from '@components/common/accordin';
import MultiSelect from '@components/common/multi_select';
import Range from '@components/range';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    TTransactionLite
} from '@xeppt/xeppt-sdk/types/transaction';
import moment from 'moment/moment';
import {
    downloadFileFromBlob,
    formatDataToXlsx,
    getTransactionAction,
    getTransactionMethod,
    getTransactionStatus,
    getTransactionStatusColor
} from '@utils/index';
import { useTransactions } from '@hooks/api/useTransactions';

const moneyOutActions = [
    ETransactionAction.LOAD_CARD,
    ETransactionAction.SEND_MONEY,
    ETransactionAction.BILL_PAYMENT
];

const columns = [
    {
        label: 'Date and Time',
        key: 'createdAt',
        isSort: true,
        render: (value: string) => moment(value).format('DD.MM.YYYY HH:mm')
    },
    {
        label: 'Transaction ID',
        key: 'id'
    },
    {
        label: 'Transaction Method',
        key: 'method',
        render: (value: ETransactionMethod) => getTransactionMethod(value)
    },
    {
        label: 'Consumer Name',
        key: 'consumerName'
    },
    { label: 'Consumer ID', key: 'consumerId' },
    {
        label: 'Action',
        key: 'action',
        render: (value: ETransactionAction) => getTransactionAction(value)
    },
    {
        label: 'Status',
        key: 'status',
        render: (value: ETransactionStatus) => (
            <Status variant={getTransactionStatusColor(value)}>
                {getTransactionStatus(value)}
            </Status>
        )
    },
    { label: 'Currency', key: 'currency' },
    {
        label: 'Amount',
        key: 'amount',
        render: (value: number, data: TTransactionLite) => (
            <PriceStatus
                isNegative={moneyOutActions.includes(data.action)}
                amount={value}
                currency="$"
            />
        )
    }
];

const transactionsType = [
    { label: 'Send Money', value: ETransactionAction.SEND_MONEY },
    { label: 'Load Card', value: ETransactionAction.LOAD_CARD },
    { label: 'Bill Payment', value: ETransactionAction.BILL_PAYMENT },
    { label: 'Request Card', value: ETransactionAction.REQUEST_CARD },
    { label: 'Receive money', value: ETransactionAction.RECEIVE_MONEY },
    { label: 'Load Balance', value: ETransactionAction.LOAD_BALANCE }
];

const TransactionsLayout = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [dateFilter, setDateFilter] = useState<string>();

    const { filter, onChangeFilter, onChangeCursor, transactions } = useTransactions();

    const handleChangeDateType = (value: string) => {
        if (dateFilter === value) {
            setDateFilter('');
            onChangeCursor(undefined);
            onChangeFilter((state) => ({
                ...state,
                dateFrom: undefined,
                dateTo: undefined
            }));
        } else {
            setDateFilter(value);
        }
        if (value === 'week') {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            onChangeFilter((state) => ({
                ...state,
                dateFrom: date,
                dateTo: new Date()
            }));
        } else if (value === 'month') {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            onChangeFilter((state) => ({
                ...state,
                dateFrom: date,
                dateTo: new Date()
            }));
        } else if (value === 'year') {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 1);
            onChangeFilter((state) => ({
                ...state,
                dateFrom: date,
                dateTo: new Date()
            }));
        }
    };

    const handleDownloadTransaction = () => {
        const formatTransaction = transactions?.data.map((item) => ({
            'Transaction ID': item.id,
            'First name': item.firstName,
            'Last name': item.lastName,
            Action: getTransactionAction(item.action),
            Amount: item.amount,
            Currency: item.currency,
            'Date ': moment(item.createdAt).format('MM/DD/YYYY HH:mm:ss'),
            Method: getTransactionMethod(item.method),
            Status: getTransactionStatus(item.status)
        }));
        downloadFileFromBlob(formatDataToXlsx(formatTransaction), 'all-transactions.csv');
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Transactions</Typography>
                    <Typography>{transactions?.data?.length} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <RangeDatePicker
                        values={[filter.dateFrom?.toString(), filter.dateTo?.toString()]}
                        onChange={(val) =>
                            onChangeFilter((state) => ({
                                ...state,
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            }))
                        }
                    />
                    <Button size="medium" variant="outlined" leftIcon="download">
                        Download
                    </Button>
                    <Button
                        size="medium"
                        variant="primary"
                        leftIcon="filter_bars"
                        onClick={() => setIsDrawerOpen(true)}>
                        Filter
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                rows={transactions?.data}
                onSortChange={() =>
                    onChangeFilter((state) => ({
                        ...state,
                        order: state.order === 'DESC' ? 'ASC' : 'DESC'
                    }))
                }
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}>
                <Input full leftIcon="search" placeholder="Search for transaction ID" />
                <Accordion label="Date and Time" isDefaultOpened>
                    <div className={styles.date_accordion}>
                        <Button
                            variant={dateFilter === 'week' ? 'primary' : 'outlined'}
                            size="small"
                            onClick={() => handleChangeDateType('week')}>
                            Last week
                        </Button>
                        <Button
                            variant={dateFilter === 'month' ? 'primary' : 'outlined'}
                            size="small"
                            onClick={() => handleChangeDateType('month')}>
                            Last month
                        </Button>
                        <Button
                            variant={dateFilter === 'year' ? 'primary' : 'outlined'}
                            size="small"
                            onClick={() => handleChangeDateType('year')}>
                            Last year
                        </Button>
                    </div>
                    <RangeDatePicker
                        full
                        values={[filter.dateFrom?.toString(), filter.dateTo?.toString()]}
                        onChange={(val) =>
                            onChangeFilter((state) => ({
                                ...state,
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            }))
                        }
                    />
                </Accordion>
                <Accordion label="Transaction Method" isDefaultOpened>
                    <MultiSelect
                        full
                        values={filter.method || []}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Action" isDefaultOpened>
                    <MultiSelect
                        full
                        values={filter.action || []}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Status" isDefaultOpened>
                    <MultiSelect
                        full
                        values={filter.status || []}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Amount" isDefaultOpened>
                    <Range
                        min={0}
                        max={1000}
                        step={1}
                        values={[filter.amountFrom || 0, filter.amountTo || 1000]}
                        onChange={(val) => {
                            onChangeFilter((state) => ({
                                ...state,
                                amountFrom: val?.[0],
                                amountTo: val?.[1]
                            }));
                        }}
                    />
                </Accordion>
            </Drawer>
        </div>
    );
};

export default TransactionsLayout;
