import { useState } from 'react';
import { TTransactionsFilter } from '@xeppt/xeppt-sdk/types/transaction';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAdminService } from '@api';

export const useTransactions = () => {
    const [cursor, setCursor] = useState<Date>();
    const [filter, setFilter] = useState<TTransactionsFilter>({
        id: undefined,
        action: undefined,
        method: undefined,
        status: undefined,
        consumerId: undefined,
        dateFrom: undefined,
        dateTo: undefined,
        amountFrom: undefined,
        amountTo: undefined,
        order: 'DESC'
    });

    const { data: transactions } = useApiQuery({
        method: () => apiAdminService.getTransactions(filter, cursor, 100),
        onSuccess: (data) => {
            console.log({ cursor: data.cursor });
        },
        deps: [filter]
    });

    return {
        transactions,
        filter,
        onChangeFilter: setFilter,
        onChangeCursor: (val: Date | undefined) => setCursor(val)
    };
};
