import { useState } from 'react';
import { apiAdminService } from '@api';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useParams } from 'react-router-dom';
import { TCard } from '@xeppt/xeppt-sdk/types';

interface IProps {
    isInitialConsumers?: boolean;
    isInitialConsumer?: boolean;
    isInitialConsumerCard?: boolean;
    isInitialConsumerCards?: boolean;
}

export const useConsumers = ({
    isInitialConsumers = false,
    isInitialConsumer = false,
    isInitialConsumerCards = false
}: IProps) => {
    const { id } = useParams();
    const [consumerCard, setConsumerCard] = useState<TCard>();
    const [consumersFilter, setConsumersFilter] = useState<{
        status?: EConsumerStatus[];
        cursor?: Date;
        limit?: number;
    }>({
        status: undefined,
        cursor: undefined,
        limit: 100
    });

    const { data: consumers } = useApiQuery({
        method: () =>
            apiAdminService.getConsumers(
                //@ts-ignore
                { status: consumersFilter.status },
                consumersFilter.cursor,
                consumersFilter.limit
            ),
        isInitialRequest: isInitialConsumers
    });

    const { data: consumer } = useApiQuery({
        method: () => apiAdminService.getConsumerById(id || ''),
        condition: !!id,
        deps: [id],
        isInitialRequest: isInitialConsumer
    });

    const getConsumerCardById = (id: string) => {
        apiAdminService.getConsumerCardById(id || '').then((data) => setConsumerCard(data));
    };

    const { data: consumerCards } = useApiQuery({
        method: () => apiAdminService.getConsumerCards(id || ''),
        condition: !!id,
        deps: [id],
        isInitialRequest: isInitialConsumerCards
    });

    const handleChangeConsumersFilter = (data: Partial<typeof consumersFilter>) => {
        setConsumersFilter((state) => ({ ...state, ...data }));
    };

    return {
        consumers,
        consumer,
        consumerCards,
        consumerCard,
        consumersFilter,
        getConsumerCardById,
        handleChangeConsumersFilter
    };
};
