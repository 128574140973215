import React, { useEffect, useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import RangeDatePicker from '@components/range_date_picker';
import Button from '@components/common/button';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Accordion from '@components/common/accordin';
import MultiSelect from '@components/common/multi_select';
import Range from '@components/range';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { useConsumers } from '@hooks/api/useConsumers';
import { TConsumerLite } from '@xeppt/xeppt-sdk/types';

const columns = (navigate: ReturnType<typeof useNavigate>) => [
    {
        label: 'Consumer ID',
        key: 'id'
    },
    {
        label: 'Name',
        key: 'name',
        render: (_: string, data: TConsumerLite) => `${data.firstName} ${data.lastName}`
    },
    {
        label: 'Email',
        key: 'email'
    },
    {
        label: 'Phone',
        key: 'phone',
        render: (_: string, data: TConsumerLite) => `${data.phoneCode} ${data.phoneNumber}`
    },
    {
        label: 'Verification',
        key: 'isVerified',
        render: (_: boolean, data: TConsumerLite) =>
            data.status !== EConsumerStatus.UNVERIFIED &&
            data.status !== EConsumerStatus.UNCONFIRMED
                ? 'Verified'
                : 'Not verified',
        filterOptions: [
            {
                label: 'Verified',
                value: 'verified'
            },
            {
                label: 'Not Verified',
                value: 'not_verified'
            }
        ]
    },
    {
        label: 'Status',
        key: 'status',
        filterOptions: [
            {
                label: 'Suspended',
                value: EConsumerStatus.SUSPENDED
            },
            {
                label: 'Active',
                value: EConsumerStatus.ACTIVE
            },
            {
                label: 'Pending',
                value: EConsumerStatus.VERIFICATION_PENDING
            },
            {
                label: 'Unverified',
                value: EConsumerStatus.UNVERIFIED
            },
            {
                label: 'Unconfirmed',
                value: EConsumerStatus.UNCONFIRMED
            },
            {
                label: 'Blocked',
                value: EConsumerStatus.BLOCKED
            }
        ]
    },
    {
        label: 'Date created',
        key: 'createdAt',
        isSort: true,
        render: (value: string) => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        label: 'Profile',
        key: 'id',
        render: (value: string) => (
            <Button
                variant="outlined"
                style={{ background: 'none' }}
                onClick={() => navigate(`${routes.consumers}/${value}`)}>
                View Profile
            </Button>
        )
    }
];

const transactionsType = [
    { label: 'E-transfer', value: 'E-transfer' },
    { label: 'Credit card', value: 'Credit card' }
];

const ConsumersLayout = () => {
    const { consumers, consumersFilter, handleChangeConsumersFilter } = useConsumers({
        isInitialConsumers: true
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [range, setRange] = useState([0, 1000]);
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Consumers</Typography>
                    <Typography>{consumers?.total} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <Input
                        className={styles.search}
                        leftIcon="search"
                        placeholder="Search for consumer name, email or ID"
                    />
                </div>
            </div>
            <Table
                //@ts-ignore
                columns={columns(navigate)}
                rows={consumers?.data}
                onFilterChange={(value) =>
                    //@ts-ignore
                    handleChangeConsumersFilter({ status: value.status as EConsumerStatus[] })
                }
                //@ts-ignore
                filter={{ status: (consumersFilter?.status as string[]) || [] }}
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}>
                <Input full leftIcon="search" placeholder="Search for transaction ID" />
                <Accordion label="Date and Time" isDefaultOpened>
                    <div className={styles.date_accordion}>
                        <Button variant="outlined" size="small">
                            Last week
                        </Button>
                        <Button variant="outlined" size="small">
                            Last month
                        </Button>
                        <Button variant="outlined" size="small">
                            Last year
                        </Button>
                    </div>
                    <RangeDatePicker full values={[new Date().toString(), new Date().toString()]} />
                </Accordion>
                <Accordion label="Transaction Type" isDefaultOpened>
                    <MultiSelect
                        full
                        values={[
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer',
                            'E-transfer'
                        ]}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Action" isDefaultOpened>
                    <MultiSelect
                        full
                        values={['E-transfer']}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Status" isDefaultOpened>
                    <MultiSelect
                        full
                        values={['E-transfer']}
                        onChange={() => {}}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Amount" isDefaultOpened>
                    <Range
                        min={0}
                        max={1000}
                        step={1}
                        values={range}
                        onChange={(val) => {
                            setRange(val);
                        }}
                    />
                </Accordion>
            </Drawer>
        </div>
    );
};

export default ConsumersLayout;
