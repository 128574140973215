import React, { useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import Status from '@components/common/status';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Select from '@components/common/select';
import { useConsumers } from '@hooks/api/useConsumers';
import moment from 'moment/moment';

const columns = (onView: (value: string) => void, onDetails: (value: string) => void) => [
    { label: 'Card ID', key: 'id' },
    { label: 'Card number', key: 'cardNumber' },
    { label: 'Card limit', key: 'usageLimit' },
    {
        label: 'Expiration Date',
        key: 'expiration',
        render: (val: Date) => moment(val).format('DD.MM.YYYY HH:mm')
    },
    { label: 'Balance', key: 'balance' },
    { label: 'Currency', key: 'currency' },
    {
        label: 'Status',
        key: 'status',
        render: (value: string) => <Status variant="success">{value}</Status>
    },
    {
        label: 'Date created',
        key: 'createdAt',
        render: (val: Date) => moment(val).format('DD.MM.YYYY HH:mm')
    },
    {
        label: 'Transactions',
        key: 'id',
        render: (value: string) => (
            <div className={styles.actions_wrapper}>
                <Button
                    onClick={() => onView(value)}
                    style={{ background: 'none' }}
                    variant="outlined">
                    View
                </Button>
                <Button onClick={() => onDetails(value)} variant="primary">
                    Card Details
                </Button>
            </div>
        )
    }
];

const CardsSection = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const { consumerCard, consumerCards, getConsumerCardById } = useConsumers({
        isInitialConsumerCards: true
    });

    const handleView = (id: string) => {
        getConsumerCardById(id);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Cards</Typography>
                </div>
            </div>
            <Table
                columns={columns(handleView, () => setIsDrawerOpen(true))}
                rows={consumerCards}
            />
            <Drawer
                className={styles.drawer}
                isOpen={isDrawerOpen}
                width={670}
                onClose={() => setIsDrawerOpen(false)}>
                <Typography className={styles.title} variant="h5">
                    Card details
                </Typography>
                <div className={styles.content}>
                    <Input value={consumerCard?.cardNumber} label="Card number" full />
                    <Select
                        value={consumerCard?.status}
                        label="Status"
                        full
                        onChange={() => {}}
                        items={[]}
                    />
                    <Input value="Physical" label="Card Type" full />
                    <Input value={consumerCard?.currency} label="Currency" full />
                    <Input
                        value={moment(consumerCard?.createdAt).format('DD.MM.YYYY HH:mm')}
                        label="Start Date"
                        full
                    />
                    <Input
                        value={moment(consumerCard?.expiration).format('DD.MM.YYYY HH:mm')}
                        label="Expiry Date"
                        full
                    />
                    <Input value={consumerCard?.usageLimit} label="Usage Limit" full />
                    {/*<Input label="Available Limit" full />*/}
                    <Input value={consumerCard?.balance} label="Balance" full />
                    {/*<Input label="Reference" full />*/}
                </div>
            </Drawer>
        </div>
    );
};

export default CardsSection;
