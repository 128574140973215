import React, { createContext, useEffect, useMemo, useState } from 'react';
import { TBusinessProfile, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';
import { apiAuthService, apiUserService } from '@api';
import { routes } from '@const/routes';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useLocales } from '@hooks/helpers/useLocales';

type TUserContext = {
    user?: TIndividualProfile | TBusinessProfile;
    handleLogout: () => void;
    refetchUser: () => void;
    isDataLoading: boolean;
};

const initialState: TUserContext = {
    user: undefined,
    handleLogout: () => Promise.resolve(),
    refetchUser: () => Promise.resolve(),
    isDataLoading: true
};

const authRoutes = [routes.signin, routes.signup];

export const UserContext = createContext<TUserContext>(initialState);

export const UserContextProvider = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState<TBusinessProfile | TIndividualProfile>();
    const [isAuth, setIsAuth] = useState(true);
    const { requestErrorLocale } = useLocales();

    const { isLoading: isUserLoading, handleRequest: refetchUser } = useApiQuery({
        method: () => apiUserService.profile(),
        condition: !isAuth,
        deps: [isAuth],
        onSuccess: (data) => {
            if (data.type) setUser(data);
        },
        onError: () => {
            navigate(routes.signin);
        }
    });

    useEffect(() => {
        if (authRoutes.includes(location.pathname)) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, [location]);

    const handleLogout = () => {
        apiAuthService
            .logout()
            .then(() => navigate(routes.signin))
            .catch(requestErrorLocale);
    };

    const isDataLoading = useMemo(() => {
        return isUserLoading;
    }, [isUserLoading]);

    return (
        <UserContext.Provider
            value={{
                user,
                handleLogout,
                refetchUser,
                isDataLoading
            }}>
            <Outlet />
        </UserContext.Provider>
    );
};
