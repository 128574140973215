import React, { FC, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';

interface IProps {
    value?: string | number;
    onChange: (value: string) => void;
    label?: string;
    placeholder?: string;
    items: {
        value: string;
        label: string;
    }[];
    full?: boolean;
    disabled?: boolean;
    isAllowSearch?: boolean;
}

const cx = classNames.bind(styles);

const Select: FC<IProps> = ({
    value = '',
    placeholder,
    disabled,
    full,
    label,
    items,
    onChange,
    isAllowSearch = false
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const ref = useRef(null);

    const handleChange = (value: string) => {
        onChange(value);
        setSearch(value);
        setIsOpen(false);
    };

    useClickOutside({
        ref,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    return (
        <div className={cx([styles.wrapper, { isFull: full }])} ref={ref}>
            <Input
                rightIcon="nav_down"
                value={
                    isAllowSearch
                        ? search
                        : items?.find((item) => item.value === value)?.label || ''
                }
                placeholder={placeholder}
                label={label}
                full={full}
                readOnly={!isAllowSearch}
                className={cx([{ isOpen }])}
                onFocus={() => setIsOpen(true)}
                onClickIcon={() => setIsOpen((state) => !state)}
                disabled={disabled}
                onChange={(val) => setSearch(val)}
            />
            <div className={cx([styles.dropdown, { isOpen }])}>
                {items
                    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))
                    .map((item, i) => (
                        <button
                            key={i}
                            onClick={() => {
                                if (!disabled) handleChange(item.value);
                            }}>
                            {item.label}
                        </button>
                    ))}
            </div>
        </div>
    );
};

export default Select;
